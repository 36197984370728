import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { AnimateOnEnter, Block, Img, measurementTokens } from '@ebinion/zeke-design-system'

const StyledComponent = styled.ol`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  gap: var(--component-padding-l);
  margin: 0;
  padding: 0;
`

const StyledItem = styled.li`
  box-sizing: border-box;
  line-height: 0;
  margin: 0;
  flex: 0 0 50px;
  filter: grayscale(100%);

  @media (min-width: ${measurementTokens.breakpoints.horizontal.s}) {
    flex: 0 0 70px;
  }

  @media (min-width: ${measurementTokens.breakpoints.horizontal.l}) {
    flex: 0 0 95px;
  }
`

export default function Companies() {
  const data = useStaticQuery(graphql`
    {
      companies: allFile(
        filter: { relativeDirectory: { eq: "companies" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          extension
          name
          publicURL
          childImageSharp {
            gatsbyImageData(width: 300)
          }
        }
      }
    }
  `)

  const renderLogo = company => {
    if (company.childImageSharp) {
      return (
        <GatsbyImage
          image={company.childImageSharp.gatsbyImageData}
          quality="80"
          alt={company.name.replace('-', ' ')}
        />
      )
    }

    return <Img src={company.publicURL} alt={company.name.replace('-', ' ')} />
  }

  return (
    <Block constrain="site" paddingTop="0" paddingBottom="0" isCentered>
      <StyledComponent>
        {data.companies.nodes.map((company, index) => (
          <StyledItem key={company.name}>
            <AnimateOnEnter kind="fadeInFromBottom" delay={`${index * 30}ms`}>
              {renderLogo(company)}
            </AnimateOnEnter>
          </StyledItem>
        ))}
      </StyledComponent>
    </Block>
  )
}
