import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { get } from 'lodash'
import styled from 'styled-components'
import {
  A,
  Button,
  Block,
  Column,
  Columns,
  H1,
  H3,
  H5,
  Matrix,
  Img,
  P,
  Rows,
  Section,
  Text,
  measurementTokens,
} from '@ebinion/zeke-design-system'

import Companies from '../components/Companies'
import Layout from '../components/Layout'
import Rounded from '../components/Rounded'
import { AppContext } from '../state'
import { openContactModal } from '../state/actions'

const ServiceWrapper = styled.div`
  /* TODO: uncomment once icons are designed */
  /* display: grid;
  grid-template-columns: 44px 1fr;
  gap: var(--component-padding); */
`
const Hero = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
`

const LeadImage = styled.div`
  flex: 1 0 25%;
  overflow: hidden;
`

const LeadImageContent = styled.div`
  width: 400%;

  > * {
    transform: translateX(-10%);
  }

  @media (min-width: ${measurementTokens.breakpoints.horizontal.m}) {
    width: 300%;
  }

  @media (min-width: ${measurementTokens.breakpoints.horizontal.l}) {
    width: 250%;
  }
`

function Service({ /* image, */ children }) {
  return (
    <ServiceWrapper>
      {/* TODO: uncomment once icons are designed */}
      {/* <div>{image}</div> */}
      <div>{children}</div>
    </ServiceWrapper>
  )
}
Service.propTypes = {
  // TODO: uncomment once icons are designed
  // image: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
}

export default function IndexPage(props) {
  const appContext = useContext(AppContext)

  const {
    data: {
      entries: { nodes: entries },
      shots: { nodes: shotNodes },
    },
  } = props

  return (
    <Layout title="Product designer, developer, and AIPMM certified product manager based in Atlanta, GA">
      <Block as="article" color="transparent" padding="none">
        <Block as="header" padding="xl" constrain="site" isCentered>
          <Block constrain="text" padding="none" isInset>
            <H1>Let&rsquo;s make something your customers will&nbsp;love</H1>
            <P size="l" noMargin>
              Zeke Binion is an Atlanta based product designer, developer, and AIPMM certified
              product manager with over 15 years of experience in&nbsp;technology.
            </P>
            <P>
              <Button
                onClick={() => {
                  appContext.dispatch(openContactModal())
                }}
              >
                Get in Touch
              </Button>
            </P>
          </Block>
        </Block>
        <Hero>
          {shotNodes.map(shot => (
            <LeadImage>
              <LeadImageContent>
                <GatsbyImage image={shot.shot.localFile.childImageSharp.gatsbyImageData} />
              </LeadImageContent>
            </LeadImage>
          ))}
        </Hero>

        <Section
          superTitle="Services"
          title="From start-ups to large-caps, I improve products for businesses of all size"
          lead="Whether you need to identify new strategic oppotunities, pay down your
      design debt, or augment your existing team, I can help you solve
      meaningful problems for your business and deliver impact to your
      users."
        >
          <Block constrain="text" padding="xl" paddingTop="0" isCentered>
            <Matrix gutter="l" maxColumns={2}>
              <Service image={<Img src="https://via.placeholder.com/150" alt="" />}>
                <H5 as="h3" noMargin>
                  Research
                </H5>
                <P size="s" color="light" noMargin>
                  Voice of customer, design research, usability testing, surveys, NPS programs,
                  customer journey mapping, and analytics
                </P>
              </Service>

              <Service image={<Img src="https://via.placeholder.com/150" alt="" />}>
                <H5 as="h3" noMargin>
                  Product Management
                </H5>
                <P size="s" color="light" noMargin>
                  Roadmapping, feature prioritization, product positioning, Lean Canvas,
                  requirements gathering, and product validation
                </P>
              </Service>

              <Service image={<Img src="https://via.placeholder.com/150" alt="" />}>
                <H5 as="h3" noMargin>
                  Product Design &amp; UX
                </H5>
                <P size="s" color="light" noMargin>
                  Design Thinking, Lean UX, UI design, rapid prototyping, Google Design Sprints, and
                  information architecture
                </P>
              </Service>
              <Service image={<Img src="https://via.placeholder.com/150" alt="" />}>
                <H5 as="h3" noMargin>
                  Software Development
                </H5>
                <P size="s" color="light" noMargin>
                  HTML, CSS, Javascript, Typescript, React, Gatsby, Next.JS, Design Systems, content
                  management systems, and web app integration
                </P>
              </Service>
            </Matrix>
          </Block>

          <Companies />
        </Section>

        <Section
          isDark
          lead="Whether you need to identify new strategic oppotunities, pay down your design debt, or augment your existing team, I can help you solve meaningful problems for your business and deliver impact to your users."
          superTitle="Case Studies"
          title="How I&rsquo;ve created delightful outcomes for web and mobile projects like yours"
        >
          <Block constrain="text" isCentered paddingBottom="0" paddingTop="0">
            <Rows spacing="xl">
              {entries.map(entry => {
                const { id, previewImage, slug, type, title } = entry
                const previewImageData = get(
                  previewImage,
                  'localFile.childImageSharp.gatsbyImageData',
                  null
                )

                return (
                  <A as={Link} key={`study-${id}`} kind="incognito" to={slug}>
                    <Columns base={5}>
                      <Column start={1} span={2}>
                        <Text color="gold" noMargin>
                          {type}
                        </Text>
                        <H3 color="knockout" noMargin respondToLinkHover>
                          {title}
                        </H3>
                      </Column>
                      <Column start={3} span={3}>
                        {!previewImageData && (
                          <Rounded>
                            <Img
                              src="https://via.placeholder.com/1600x1200?text=Image+Coming+Soon"
                              alt="Image coming soon"
                            />
                          </Rounded>
                        )}
                        {previewImageData && (
                          <Rounded>
                            <GatsbyImage image={previewImageData} alt={entry.previewImage.title} />
                          </Rounded>
                        )}
                      </Column>
                    </Columns>
                  </A>
                )
              })}
            </Rows>
          </Block>
        </Section>
      </Block>
    </Layout>
  )
}
IndexPage.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query {
    entries: allContentfulPortfolioEntry(
      sort: { fields: year, order: DESC }
      filter: { isPublished: { eq: true } }
    ) {
      nodes {
        id
        slug
        title
        year
        type
        previewImage {
          title
          localFile {
            childImageSharp {
              gatsbyImageData(
                height: 1200
                width: 1600
                placeholder: BLURRED
                outputPixelDensities: 1.5
                transformOptions: { cropFocus: NORTH, fit: FILL }
              )
            }
          }
        }
      }
    }
    shots: allContentfulShots(limit: 4, sort: { order: DESC, fields: shot___createdAt }) {
      nodes {
        title
        id
        shot {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 1200, width: 1600, placeholder: BLURRED)
            }
          }
          title
        }
      }
    }
  }
`
